import React from 'react';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import isNull from 'lodash/isNull';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { AuthState } from '@phillipsauctionhouse/auth-js/types';
import MenuItem from './MenuItem';
import { setI18nLinkLanguage } from '../../utils/getI18nLabel';
import { user as userPropTypes } from '../PropTypes/proptypes';
import { headerAuctionProps, privateSalesMenuProps } from './proptypes';
import { useAuth } from '../Auth';
import sendAnalytics from '../../utils/sendAnalytics';

const OffCanvas = ({
  activeNode,
  amBidsUrl,
  getI18nLabel,
  handleLanguageChange,
  handleLogout,
  language,
  isOpen,
  handleLogin,
  headerAuctions,
  privateSalesMenu
}) => {
  const { authState } = useAuth()
  const activeKebab = isNull(activeNode) ? '' : kebabCase(activeNode);
  const noLabel = true;
  const getI18nLink = setI18nLinkLanguage(language);
  const sendAnalyticsCall = (eventCategory, eventAction, eventLabel = 'www.phillips.com') => {
    sendAnalytics({
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel
    });
  }
  return (
    <nav
      aria-hidden={!isOpen}
      className={classNames('phillips__nav__off-canvas-wrapper phillips__nav__off-canvas-wrapper--left', {
        'phillips__nav__off-canvas-wrapper--open phillips__nav__off-canvas-wrapper--open--left': isOpen
      })}
    >
      <ul className="phillips__nav__off-canvas">
        <MenuItem language={language} activeNode={activeKebab} name="auctions">
          {headerAuctions?.map((auction, index) => {
            while(index <= 14) {
              return (<li className="phillips__nav__off-canvas__item" key={auction.saleNumber}>
                <a
                  className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
                  href={getI18nLink(auction.url)}
                  title={getI18nLabel({ label: auction.title, defaultValue: auction.title })}
                >
                  {getI18nLabel({ label: auction.title, defaultValue: auction.title })}
                  {auction.includeLocation && auction.location
                    ? `, ${getI18nLabel({ label: camelCase(auction.location) })}`
                    : null}
                </a>
              </li>);
            }
          })}
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/calendar')}
              title={getI18nLabel({ label: 'viewAllAuctions', defaultValue: 'View All Auctions' })}
            >
              {getI18nLabel({ label: 'viewAllAuctions', defaultValue: 'View All Auctions' })}
            </a>
          </li>
          {/* <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href="/digitalsaleroom"
              title={getI18nLabel({ label: 'digitalSaleroom' })}
            >
              {getI18nLabel({ label: 'digitalSaleroom' })}
            </a>
          </li> */}
          {/* <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/livestream')}
              title="Phillips Live Stream"
            >
              Phillips Live Stream
            </a>
          </li> */}
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/auctions/past')}
              title={getI18nLabel({ label: 'auctionResults' })}
            >
              {getI18nLabel({ label: 'auctionResults' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/artists')}
              title={getI18nLabel({ label: 'artistsAndMakers' })}
            >
              {getI18nLabel({ label: 'artistsAndMakers' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/buysell/buy?auctions')}
              title={getI18nLabel({ label: 'howToBuy' })}
            >
              {getI18nLabel({ label: 'howToBuy' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/article/58561057/auction-bidding-anywhere')}
              title={getI18nLabel({ label: 'remoteBidding' })}
            >
              {getI18nLabel({ label: 'remoteBidding' })}
            </a>
          </li>
        </MenuItem>
        {/* <MenuItem language={language} activeNode={activeKebab} name="calendar"> */}
        <li
          className={classNames('phillips__nav__off-canvas__item', {
            'phillips__nav__off-canvas__item--selected': activeNode === 'calendar'
          })}
        >
          <a
            className="phillips__nav__off-canvas__item__link"
            href={getI18nLink('/calendar')}
            title={getI18nLabel({ label: 'calendar' })}
          >
            {getI18nLabel({ label: 'calendar' })}
          </a>
        </li>
        {/* </MenuItem> */}
        <MenuItem language={language} activeNode={activeKebab} name="departments">
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href="/modern-and-contemporary-art"
              title={getI18nLabel({ label: 'ModernAndContemporaryArt' })}
            >
              {getI18nLabel({ label: 'ModernAndContemporaryArt' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href="/design"
              title={getI18nLabel({ label: 'design' })}
            >
              {getI18nLabel({ label: 'design' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href="/editions"
              title={getI18nLabel({ label: 'editions' })}
            >
              {getI18nLabel({ label: 'editions' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href="/jewels"
              title={getI18nLabel({ label: 'jewels' })}
            >
              {getI18nLabel({ label: 'jewels' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href="/photographs"
              title={getI18nLabel({ label: 'photographs' })}
            >
              {getI18nLabel({ label: 'photographs' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href="/watches"
              title={getI18nLabel({ label: 'watches' })}
            >
              {getI18nLabel({ label: 'watches' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href="/private-sales-department"
              title={getI18nLabel({ label: 'privateSales' })}
            >
              {getI18nLabel({ label: 'privateSales' })}
            </a>
          </li>
        </MenuItem>
        <li
          className={classNames('phillips__nav__off-canvas__item', {
          'phillips__nav__off-canvas__item--selected': activeNode === 'exhibitions'
        })}
        >
          <a
            href="/exhibitions"
            title={getI18nLabel({ label: 'exhibitions' })}
            className="phillips__nav__off-canvas__item__link"
          >
            {getI18nLabel({ label: 'exhibitions' })}
          </a>
        </li>
        
        <li
          className='phillips__nav__off-canvas__item'
        >
          <a
            href={getI18nLink('/store/perpetual')}
            className="phillips__nav__off-canvas__item__link"
            title={getI18nLabel({ label: 'perpetual' })}
          >
            {getI18nLabel({ label: 'perpetual' })}
          </a>
        </li>
        <li
          className={classNames('phillips__nav__off-canvas__item', {
            'phillips__nav__off-canvas__item--selected': activeNode === 'Dropshop'
          })}
        >
          <a
            href="http://dropshop.phillips.com"
            className="phillips__nav__off-canvas__item__link"
            title='dropshop'
            onClick={() => {sendAnalyticsCall('Dropshop click', 'Clicked Header Nav Link')}}
          >
            Dropshop
          </a>
        </li>
        <MenuItem language={language} activeNode={activeKebab} name="buy-sell" labelName="buyAndSell">
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/buysell/buy')}
              title={getI18nLabel({ label: 'howToBuy' })}
            >
              {getI18nLabel({ label: 'howToBuy' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/buysell/sell')}
              title={getI18nLabel({ label: 'howToSell' })}
            >
              {getI18nLabel({ label: 'howToSell' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/article/58561057/auction-bidding-anywhere')}
              title={getI18nLabel({ label: 'remoteBidding' })}
            >
              {getI18nLabel({ label: 'remoteBidding' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/buysell/private')}
              title={getI18nLabel({ label: 'privateServices' })}
            >
              {getI18nLabel({ label: 'privateServices' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/trusts-estates-valuations')}
              title={getI18nLabel({ label: 'trustsEstatesAndValuations' })}
            >
              {getI18nLabel({ label: 'trustsEstatesAndValuations' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/fiduciaryservices')}
              title={getI18nLabel({ label: 'fiduciaryServices' })}
            >
              {getI18nLabel({ label: 'fiduciaryServices' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/catalogues/buy')}
              title={getI18nLabel({ label: 'buyCatalogues' })}
            >
              {getI18nLabel({ label: 'buyCatalogues' })}
            </a>
          </li>
          {/* <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/catalogues/subscribe')}
              title={getI18nLabel({ label: 'catalogueSubscriptions' })}
            >
              {getI18nLabel({ label: 'catalogueSubscriptions' })}
            </a>
          </li> */}
        </MenuItem>
        <li
          className={classNames('phillips__nav__off-canvas__item', {
            'phillips__nav__off-canvas__item--selected': activeNode === 'Editorial'
          })}
        >
          <a
            href={getI18nLink('/editorial')}
            className="phillips__nav__off-canvas__item__link"
            title={getI18nLabel({ label: 'editorial' })}
          >
            {getI18nLabel({ label: 'editorial' })}
          </a>
        </li>
        <MenuItem language={language} activeNode={activeKebab} name="about" labelName="aboutUs">
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/about')}
              title={getI18nLabel({ label: 'ourHistory' })}
            >
              {getI18nLabel({ label: 'ourHistory' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/team')}
              title={getI18nLabel({ label: 'ourTeam' })}
            >
              {getI18nLabel({ label: 'ourTeam' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href={getI18nLink('/location')}
              title={getI18nLabel({ label: 'locations' })}
            >
              {getI18nLabel({ label: 'locations' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href="/press"
              title={getI18nLabel({ label: 'press' })}
            >
              {getI18nLabel({ label: 'press' })}
            </a>
          </li>
          <li className="phillips__nav__off-canvas__item">
            <a
              className="phillips__nav__off-canvas__item__link phillips__nav__off-canvas__item__link--submenu "
              href="/careers"
              title={getI18nLabel({ label: 'careers' })}
            >
              {getI18nLabel({ label: 'careers' })}
            </a>
          </li>
        </MenuItem>
        {authState === AuthState.LoggedIn ? (
            <li className="phillips__nav__off-canvas__item">
              <a
                className="phillips__nav__off-canvas__item__link"
                href={getI18nLink('/account')}
                title={getI18nLabel({ label: 'myAccount' })}
              >
                {getI18nLabel({ label: 'myAccount' })}
              </a>
            </li>
        ) : (
          <li className="phillips__nav__off-canvas__item phillips__nav__off-canvas__item--no-children">
            <button
              className="phillips__nav__off-canvas__item__button phillips__nav__off-canvas__item__button--no-children"
              onClick={handleLogin}
              title={getI18nLabel({ label: 'logIn' })}
              type="button"
              value="login"
            >
              {getI18nLabel({ label: 'logIn' })}
            </button>
          </li>
        )}
        {language === 'en' ? (
          <MenuItem language={language} name="English" noLabel={noLabel}>
            <li className="phillips__nav__off-canvas__item">
              <button
                className="phillips__nav__off-canvas__item__button phillips__nav__off-canvas__item__button--no-children language"
                onClick={() => handleLanguageChange("zh")}
                title="Chinese"
                type="button"
                value="zh"
              >
                中文
              </button>
            </li>
          </MenuItem>
        ) : (
          <MenuItem language={language} name="中文" noLabel={noLabel}>
            <li className="phillips__nav__off-canvas__item">
              <button
                className="phillips__nav__off-canvas__item__button phillips__nav__off-canvas__item__button--no-children language"
                onClick={() => handleLanguageChange("en")}
                title="English"
                type="button"
                value="en"
              >
                English
              </button>
            </li>
          </MenuItem>
        )}

        <li className="phillips__nav__off-canvas__filler" />
      </ul>
    </nav>
  );
};

OffCanvas.defaultProps = {
  activeNode: null
};

OffCanvas.propTypes = {
  activeNode: PropTypes.string,
  getI18nLabel: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  headerAuctions: headerAuctionProps.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  privateSalesMenu: privateSalesMenuProps.isRequired,
  user: PropTypes.shape(userPropTypes).isRequired
};

export default OffCanvas;
